module.exports = [{
      plugin: require('/workspace/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"backgroundColor":"transparent"},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MFFFXPX","includeInDevelopment":false},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/workspace/src/cms/cms.js"},
    },{
      plugin: require('/workspace/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
