// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-cities-index-js": () => import("/workspace/src/templates/cities/index.js" /* webpackChunkName: "component---src-templates-cities-index-js" */),
  "component---src-templates-events-index-js": () => import("/workspace/src/templates/events/index.js" /* webpackChunkName: "component---src-templates-events-index-js" */),
  "component---src-templates-legal-index-js": () => import("/workspace/src/templates/legal/index.js" /* webpackChunkName: "component---src-templates-legal-index-js" */),
  "component---src-templates-pages-contact-index-js": () => import("/workspace/src/templates/pages/contact/index.js" /* webpackChunkName: "component---src-templates-pages-contact-index-js" */),
  "component---src-templates-pages-index-index-js": () => import("/workspace/src/templates/pages/index/index.js" /* webpackChunkName: "component---src-templates-pages-index-index-js" */),
  "component---src-templates-pages-photos-index-js": () => import("/workspace/src/templates/pages/photos/index.js" /* webpackChunkName: "component---src-templates-pages-photos-index-js" */),
  "component---src-pages-404-js": () => import("/workspace/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-birmingham-js": () => import("/workspace/src/pages/birmingham.js" /* webpackChunkName: "component---src-pages-birmingham-js" */),
  "component---src-pages-durham-js": () => import("/workspace/src/pages/durham.js" /* webpackChunkName: "component---src-pages-durham-js" */),
  "component---src-pages-manchester-js": () => import("/workspace/src/pages/manchester.js" /* webpackChunkName: "component---src-pages-manchester-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/workspace/.cache/data.json")

